<template>
  <v-app>
    <Menu ref="menu" />

    <v-app-bar app flat v-if="isMobile">
      <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>Portal de Trámites</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid class="main-container">
        <div class="content-area">
          <router-view />
        </div>

        <!-- Footer dentro del container -->
        <v-footer color="grey lighten-3" class="px-2 footer-container">
          <v-row align="center" no-gutters justify="end">
            <v-col cols="12" sm="auto">
              <div class="d-flex align-center">
                <v-btn text @click="showContactDialog = true">
                  <v-icon left>mdi-phone</v-icon>
                  Contacto
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-footer>

        <!-- Diálogo de contacto -->
        <v-dialog v-model="showContactDialog" max-width="500">
          <v-card class="contact-dialog">
            <v-card-title
              class="headline primary white--text pa-4 d-flex flex-column"
            >
              <div class="d-flex align-center w-100 mb-2">
                <v-icon large color="white" class="mr-3">mdi-headset</v-icon>
                <span class="title-text">Centro de Atención a Distancia </span>
                <v-spacer></v-spacer>
                <!-- <v-btn icon dark @click="showContactDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
              </div>
              <span class="subtitle-text font-weight-light">(CAD)</span>
            </v-card-title>

            <v-card-text class="pt-6">
              <v-list>
                <v-list-item
                  v-for="contacto in contactos"
                  :key="contacto.title"
                  class="contact-item mb-2"
                  :href="
                    contacto.icon === 'mdi-email'
                      ? 'mailto:' + contacto.title
                      : 'tel:' + contacto.title
                  "
                >
                  <v-list-item-icon>
                    <v-avatar
                      size="36"
                      :color="contacto.color"
                      class="white--text"
                    >
                      <v-icon dark>{{ contacto.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">{{
                      contacto.title
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                      {{
                        contacto.icon === "mdi-email"
                          ? "Correo electrónico"
                          : "Teléfono"
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon :color="contacto.color">mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4 justify-center">
              <v-btn
                color="primary"
                @click="showContactDialog = false"
                class="px-4"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Menu from "@/components/menu.vue";

export default {
  name: "MainLayout",
  components: {
    Menu,
  },
  data: () => ({
    isMobile: false,
    showContactDialog: false,
    contactos: [
      {
        title: "atencionadistancia@lacosta.gob.ar",
        color: "primary",
        icon: "mdi-email",
        subtitle: "Correo electrónico",
      },
      {
        title: "02246-433025",
        color: "teal",
        icon: "mdi-phone",
        subtitle: "Línea principal",
      },
      {
        title: "02246-433003",
        color: "teal",
        icon: "mdi-phone",
        subtitle: "Atención al cliente",
      },
      {
        title: "02246-433071",
        color: "teal",
        icon: "mdi-phone",
        subtitle: "Soporte técnico",
      },
      {
        title: "02246-433036",
        color: "teal",
        icon: "mdi-phone",
        subtitle: "Consultas generales",
      },
    ],
  }),
  mounted() {
    this.checkScreen();
    window.addEventListener("resize", this.checkScreen);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    checkScreen() {
      this.isMobile = window.innerWidth < 960;
    },
    toggleMenu() {
      if (this.$refs.menu) {
        this.$refs.menu.drawer = !this.$refs.menu.drawer;
      }
    },
  },
};
</script>

<style>
.v-data-table__expanded__content {
  background-color: #4f798f6e !important;
  height: 320px !important;
}
.bordecolor {
  border: 2px solid #4f798f !important;
}
.radiocard {
  border-radius: 30px !important;
}
.toolbardatos {
  height: 135px !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.color {
  color: #40a5dd !important;
}
.footertotal {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.footerpago {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.toolbarprincipal .v-toolbar__content {
  padding-right: 0px !important;
}
.margen {
  margin-top: -25px;
}
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(
    5px
  ); /* Ajusta el valor de desenfoque según lo necesites */
  z-index: 1;
}

.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.alert {
  z-index: 2;
}

.error-alert {
  animation: shake 0.5s ease-in-out infinite alternate;
  background-color: #ffcccc !important;
  padding: 30px;
}
.ancho_tabla {
  width: 100%;
}
.tabla {
  border: 2px solid;
  border-color: #4f798f;
}
.v-btn {
  letter-spacing: 0;
}
.botones_pagar {
  width: 95px;
}
/* .letra-custom {
  font-family: inherit;
  font-style: italic;
} */
.v-data-table-header .v-simple-checkbox .v-icon {
  color: white; /* Cambia el color del ícono a blanco */
}
.custom-header-style .v-data-table-header {
  background-color: #4f798f; /* Cambia este valor al color que desees */
}
.disabled-row {
  background-color: #f5f5f5; /* Puedes cambiar el color para que parezca deshabilitada */
  color: #b0b0b0; /* Cambia el color del texto si lo deseas */
  pointer-events: none; /* Desactiva todos los eventos en la fila */
}

.contenedor-todo {
  background: #d9d9d9;
  overflow-y: auto;
}
.custom-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.divtoolbar {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.dividers {
  width: 85%;
  border: 1px solid;
  border-color: #638699;
}

.content-col {
  padding-bottom: 60px;
  padding-left: 65px;
  min-height: 100vh;
  flex-grow: 1;
}

.calendar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: #4f798f;
}

.calendar-month {
  font-size: 18px;
  font-weight: medium;
  width: 100%;
  background-color: #4f798f;
  text-align: center;
}

.calendar-date {
  font-size: 24px;
  font-weight: bold;
  color: #4f798f;
}
.margenappbar {
  padding: 0px !important;
  margin-top: 64px !important;
}
.mayuscula {
  text-transform: uppercase;
}
.custom-actions {
  display: flex;
  justify-content: center;
  background-color: #cacaca;
}

.cards_metodos {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.letra_pagos {
  font-family: "Encode Sans", sans-serif !important;
  font-weight: bold !important;
  font-size: 12pt !important;
}
</style>

<style scoped>
.v-main {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  background-color: var(--v-background-base);
}

.main-container {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
}

.content-area {
  width: 100%;
  margin: 0 auto;
}

.footer-container {
  position: fixed !important;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  margin-top: 50px;
  bottom: 0;
  left: 0;
}

/* Eliminar cualquier padding extra del v-main__wrap */
:deep(.v-main__wrap) {
  padding: 0 !important;
}

@media (max-width: 600px) {
  .content-area {
    padding: 16px;
  }
}

/* Estilos para el diálogo de contacto */
.contact-dialog {
  border-radius: 12px;
  overflow: hidden;
}

.contact-dialog :deep(.v-card__title) {
  padding: 16px 20px;
  line-height: 1.4;
}

.contact-dialog .title-text {
  font-size: 1.25rem;
  font-weight: 500;
  white-space: normal;
  line-height: 1.2;
}

.contact-dialog .subtitle-text {
  font-size: 1rem;
  opacity: 0.9;
}

/* Ajustes responsive */
@media (max-width: 600px) {
  .contact-dialog {
    margin: 16px;
  }

  .contact-dialog .title-text {
    font-size: 1.1rem;
  }

  .contact-dialog .subtitle-text {
    font-size: 0.9rem;
  }
}

.contact-item {
  border-radius: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.contact-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.contact-item :deep(.v-list-item__title) {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.contact-item :deep(.v-list-item__subtitle) {
  color: rgba(0, 0, 0, 0.6);
}
</style>
