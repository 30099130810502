<template>
  <!-- <v-app style="width:100%;"> -->
  <!-- <v-row> -->
  <v-col cols="12" style="padding: 30px">
    <v-card
      style="border: 1px solid; margin-bottom: 20px"
      v-if="datosproveedor.estado == 0 || datosproveedor.estado == 5"
    >
      <v-form
        @submit.prevent="EnviarSolicitud"
        ref="formproveedores"
        v-model="validformproveedores"
      >
        <v-toolbar dark color="claro" flat>
          <v-toolbar-title
            >Solicitud de registro de proveedores</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-card outlined class="pa-2">
                <h3 class="mb-5">Datos Personales</h3>
                <v-row>
                  <v-col md="4" sm="6" class="no-padding">
                    <v-text-field
                      name="cuit"
                      label="CUIT"
                      :value="datosproveedor.user[0].cuit"
                      readonly
                      placeholder=""
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col md="4" sm="6" class="no-padding">
                    <v-text-field
                      name="apellido"
                      label="APELLIDO"
                      :value="datosproveedor.form[0].apellido"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="4" sm="6" class="no-padding">
                    <v-text-field
                      name="nombre"
                      label="NOMBRE"
                      :value="datosproveedor.form[0].nombre"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="razon_social"
                      label="RAZÓN SOCIAL"
                      :value="datosproveedor.form[0].razonsocial"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="nombre_fantasia"
                      label="NOMBRE DE FANTASÍA"
                      :value="datosproveedor.form[0].nombrefantasia"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-select
                      item-value="pktipoproveedor"
                      item-text="tipo_proveedor"
                      name="fktipo_proveedor"
                      label="Tipo de Proveedor"
                      :items="tiposProveedores"
                      :value="datosproveedor.form[0].fktipoproveedor"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-select
                      item-text="tipo_sociedad"
                      item-value="pktiposociedad"
                      name="fktipo_sociedad"
                      label="Tipo de Sociedad"
                      :items="tiposSociedades"
                      :value="datosproveedor.form[0].fktiposociedad"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-select
                      item-text="situacionafip"
                      item-value="pksituacionafip"
                      name="fksituacion_afip"
                      label="Situación AFIP"
                      :items="situacionesAfip"
                      :value="datosproveedor.form[0].fksituacionafip"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="nro_habilitacion"
                      label="N° Habilitación"
                      :value="datosproveedor.form[0].nrohabilitacion"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="nro_ing_brutos"
                      label="N° Ingresos Brutos"
                      :value="datosproveedor.form[0].nroingresosbrutos"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-select
                      name="fkrubro"
                      label="Rubros"
                      item-text="rubro"
                      item-value="pkrubro"
                      :items="rubros"
                      :value="datosproveedor.form[0].fkrubro"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="12" sm="6" class="no-padding">
                    <v-text-field
                      name="cbu"
                      label="CBU"
                      :value="datosproveedor.form[0].cbu"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card outlined class="pa-2 mt-2">
                <h3>Contacto</h3>
                <v-row class="mt-2">
                  <v-col md="2" sm="6" class="no-padding">
                    <v-text-field
                      name="pref_cel"
                      label="Pref-Cel"
                      :value="datosproveedor.form[0].prefijo_celular"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="4" sm="6" class="no-padding">
                    <v-text-field
                      name="celular"
                      label="Celular"
                      :value="datosproveedor.form[0].celular"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="2" sm="6" class="no-padding">
                    <v-text-field
                      name="pref_tel"
                      label="Pref-Tel"
                      :value="datosproveedor.form[0].prefijo_telefono"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="4" sm="6" class="no-padding">
                    <v-text-field
                      name="telefono"
                      label="Teléfono"
                      :value="datosproveedor.form[0].telefono"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="12" sm="6" class="no-padding">
                    <v-text-field
                      name="mail"
                      label="Dirección de Correo Electrónico"
                      :value="datosproveedor.form[0].mail"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-card outlined class="pa-2">
                <h3>Domicilio Postal</h3>
                <v-row class="mt-2">
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="postal_localidad"
                      label="Localidad"
                      :value="datosproveedor.form[0].localidadpostal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="postal_calle"
                      label="Calle"
                      :value="datosproveedor.form[0].callepostal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="postal_num_calle"
                      label="Número"
                      :value="datosproveedor.form[0].nropostal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="3" sm="6" class="no-padding">
                    <v-text-field
                      name="postal_piso"
                      label="Piso"
                      :value="datosproveedor.form[0].pisopostal"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="3" sm="6" class="no-padding">
                    <v-text-field
                      name="postal_dpto"
                      label="Dpto"
                      :value="datosproveedor.form[0].dptopostal"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card outlined class="pa-2 mt-2">
                <h3>Domicilio Legal</h3>
                <v-row class="mt-2">
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="legal_localidad"
                      label="Localidad"
                      :value="datosproveedor.form[0].localidadlegal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="legal_calle"
                      label="Calle"
                      :value="datosproveedor.form[0].callelegal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="6" sm="6" class="no-padding">
                    <v-text-field
                      name="legal_num_calle"
                      label="Número"
                      :value="datosproveedor.form[0].nrolegal"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="3" sm="6" class="no-padding">
                    <v-text-field
                      name="legal_piso"
                      label="Piso"
                      :value="datosproveedor.form[0].pisolegal"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                  <v-col md="3" sm="6" class="no-padding">
                    <v-text-field
                      name="legal_dpto"
                      label="Dpto"
                      :value="datosproveedor.form[0].dptolegal"
                      filled
                      outlined
                      dense
                      :readonly="datosproveedor.estado == 5"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card outlined class="pa-2 mt-2">
                <v-row>
                  <v-col md="12">
                    <h3>Documentación a Adjuntar</h3>
                  </v-col>
                  <v-col md="6">
                    <v-file-input
                      name="adjunto_dni"
                      label="Cargue DNI"
                      v-model="adjunto_dni"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                    <v-file-input
                      name="adjunto_iibb"
                      label="Cargue Constancia IIBB/CM"
                      v-model="adjunto_iibb"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                    <v-file-input
                      name="adjunto_autoridades"
                      label="Cargue Designación Autoridades"
                      v-model="adjunto_autoridades"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                    <!-- <ul>
                      <li>DNI</li>
                      <li>Constancia de AFIP</li>
                      <li>Constancia IIBB/CM</li>
                      <li>Acta Estatuto</li>
                      <li>Designación Autoridades</li>
                      <li>CBU</li>
                    </ul> -->
                  </v-col>
                  <v-col md="6">
                    <v-file-input
                      name="adjunto_afip"
                      label="Cargue Constancia de AFIP"
                      v-model="adjunto_afip"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                    <v-file-input
                      name="adjunto_acta_estatuto"
                      label="Cargue Acta Estatuto"
                      v-model="adjunto_acta_estatuto"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                    <v-file-input
                      name="adjunto_cbu"
                      label="Cargue CBU"
                      v-model="adjunto_cbu"
                      :rules="[fileSizeRule]"
                      filled
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-2 justify-center">
          <v-btn
            dark
            color="claro"
            type="submit"
            id="btnenviarsolicitud"
            style="margin-bottom: 10px"
            v-if="datosproveedor.estado != 5"
          >
            Enviar Solicitud
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-row
      justify="center"
      :class="
        datosproveedor.estado == 1 || datosproveedor.estado == 3
          ? 'full-height d-flex align-center'
          : ''
      "
    >
      <v-col cols="8" v-if="datosproveedor.estado == 1">
        <v-card class="solicitudes">
          <v-row align="center">
            <v-col cols="10">
              <v-card-title
                class="text-h4 font-weight-bold grey--text text--darken-2"
                >{{ datosproveedor.titulo }}</v-card-title
              >
              <v-card-subtitle class="text-h6 grey--text">{{
                datosproveedor.msj
              }}</v-card-subtitle>
            </v-col>
            <v-col cols="2">
              <v-icon class="icono" color="grey"
                >mdi-timer-sand-complete</v-icon
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="datosproveedor.estado == 2">
        <v-card class="solicitudes">
          <v-row align="center">
            <v-col cols="10">
              <v-card-title
                class="text-h4 font-weight-bold grey--text text--darken-2"
                >Su solicitud fue aceptada</v-card-title
              >
              <v-card-subtitle class="text-h6 grey--text">
                Proximamente tendrá acceso a estos trámites
              </v-card-subtitle>
              <v-btn
                color="claro"
                dark
                small
                class="ml-3"
                @click="datosproveedor.estado = 5"
              >
                VER DATOS
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-icon class="icono mr-4" color="grey">mdi-check-bold</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="datosproveedor.estado == 3">
        <v-card class="solicitudes">
          <v-row align="center">
            <v-col cols="10">
              <v-card-title
                class="text-h4 font-weight-bold grey--text text--darken-2"
                >{{ datosproveedor.titulo }}</v-card-title
              >
              <v-card-subtitle class="text-h6 grey--text text--darken-2">{{
                datosproveedor.msj
              }}</v-card-subtitle>

              <v-divider></v-divider>

              <v-col cols="12" class="mx-auto grey--text text--darken-2"
                >Puede editar el formulario para modificar los datos incorrectos
                y volver a realizar la inscripción.</v-col
              >
              <v-btn
                color="claro"
                dark
                small
                class="ml-3"
                @click="datosproveedor.estado = 0"
              >
                EDITAR FORMULARIO
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-icon class="icono" color="grey">mdi-exclamation-thick</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
  <!-- </v-row> -->
  <!-- </v-app> -->
</template>

<script>
export default {
  props: {
    datosproveedor: Object,
  },
  data: () => ({
    validformproveedores: true,
    user: { checkprov: {} },
    tiposProveedores: [],
    tiposSociedades: [],
    situacionesAfip: [],
    rubros: [],
    adjunto_dni: null,
    adjunto_afip: null,
    adjunto_acta_estatuto: null,
    adjunto_autoridades: null,
    adjunto_cbu: null,
    adjunto_iibb: null,
  }),

  components: {},

  computed: {},

  watch: {},

  mounted() {
    this.LlenarRubros();
  },

  methods: {
    LlenarRubros() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}proveedores/rubros`)
        .then(function (response) {
          console.log("rubros", response);
          obj.rubros = response.data;
          obj.LlenarSituaciones();
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    LlenarSituaciones() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}proveedores/situacion-afip`)
        .then(function (response) {
          console.log("situaciones", response);
          obj.situacionesAfip = response.data;
          obj.LlenarTiposProveedores();
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    LlenarTiposProveedores() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}proveedores/tipos-proveedores`)
        .then(function (response) {
          console.log("tipos proveedores", response);
          obj.tiposProveedores = response.data;
          obj.LlenarTiposSociedades();
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    LlenarTiposSociedades() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}proveedores/tipos-sociedades`)
        .then(function (response) {
          console.log("tipos sociedades", response);
          obj.tiposSociedades = response.data;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    EnviarSolicitud(event) {
      console.log("adjunto", this.files);
      const formData = new FormData(event.target);
      if (this.$refs.formproveedores.validate()) {
        console.log("formulario", formData);
        var obj = this;
        this.$ajax
          .post(
            `${this.$apitramites}proveedores/formulario/guardar`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then(function (response) {
            console.log("Enviar solicitud proveedor", response);
            if (response) {
              obj.$swal.fire({
                title: "¡Exito!",
                text: "Solicitud enviada con exito",
                icon: "success",
              });
              obj.$refs.formproveedores.reset();
              obj.$emit("check");
            }
          })
          .catch(function (error) {
            console.log("error", error);
            obj.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      }
    },
    fileSizeRule(file) {
      // Validación del tamaño del archivo
      if (file) {
        return file.size < 10000000 || "El archivo debe ser menor a 10MB";
      }
    },
  },
};
</script>

<style scoped>
.borde {
  border: 2px solid #ccc;
  border-radius: 8px; /* Opcional: para esquinas redondeadas */
}
.icono {
  display: flex;
  align-items: center;
  font-size: 85px;
}
.margen {
  margin-top: -25px;
}
.dropzone {
  min-height: 150px;
  border: 9px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;

  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.dropzone .dz-message {
  text-align: center;
  margin: 3em 0;
}
.solicitudes {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  background-color: #ccc;
  padding: 10px;
}
.full-height {
  height: 100vh;
}
.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
